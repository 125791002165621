
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center; /* Center text alignment */
}

.standardTextField {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    margin: 10px 0 10px 0;
}

.mailListItem {
    height: 10vh;
    border: 1px solid grey;

}

.mailListLi {
    height: 10vh;
}

.mailListItem:hover {
    background-color: lightgrey;
    cursor: pointer;
}

.icon-item:hover {
    background-color: #0070f3;
}

.icon-item {
    text-align: center;
    flex: 1;
    cursor: pointer
}

.message-action-icon-style {
    font-size: 1rem;
    display: block;
    margin-bottom: 8px
}

.StandardButton {
    padding: 10px 20px;
    background-color: #0070f3;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}


